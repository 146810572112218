import Axios from "axios";

// const API_ENDPOINT = `https://api.dragonball99.me`;
// const API_ENDPOINT = `https://api.auto4bet.co`;
// const API_ENDPOINT = `https://api.super4bet.com`;
// const API_ENDPOINT = `https://api.ufobet788.com`;
// const API_ENDPOINT = `https://api.vegasvip88.vip`;
// const API_ENDPOINT = `https://api.wins69.info`;
const API_ENDPOINT = `https://api.betwin999.co`;
// const API_ENDPOINT = `https://api.betremix77.vip`;
// const API_ENDPOINT = `https://api.spinxbet24.bet`;
// const API_ENDPOINT = `https://api.caviar66.com`;
// const API_ENDPOINT = `https://api.meetang168.bet`;

export const HTTP = Axios.create({
  baseURL: API_ENDPOINT,
});